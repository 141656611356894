.navbar {
  .desktop-list-item {
    @apply px-3 py-2 whitespace-nowrap rounded-md text-sm font-medium text-indigo-200;

    &:hover {
      @apply text-white bg-indigo-600;
    }

    &:focus {
      @apply outline-none text-white bg-indigo-600;
    }

    @media (min-width: 1024px) {
      @apply ml-4;
    }

    &.active-item {
      @apply text-white bg-indigo-800;

      &:focus {
        @apply outline-none text-white bg-indigo-600;
      }
    }
  }

  .mobile-list-item {
    @apply mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-200;

    &:hover {
      @apply text-white bg-indigo-600;
    }

    &:focus {
      @apply outline-none text-white bg-indigo-600;
    }

    &.active-item {
      @apply text-white bg-indigo-800;

      &:focus {
        @apply outline-none text-white bg-gray-700;
      }
    }
  }
}
