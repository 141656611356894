.dropdown-bg-sky {
  @apply text-white rounded bg-sky-600 border border-sky-700 relative;

  &:hover {
    @apply bg-sky-700;
  }
}

.dropdown-bg-pink {
  @apply text-white rounded bg-pink-600 border border-pink-700 relative;

  &:hover {
    @apply bg-pink-700;
  }
}

.dropdown-bg-teal {
  @apply text-white rounded bg-teal-600 border border-teal-700 relative;

  &:hover {
    @apply bg-teal-700;
  }
}
