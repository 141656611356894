.bg-category-disasters {
  background-color: #db1001; //fallback color in case display-p3 is not supported
  background-color: color(display-p3 0.8588235294 0.05882352941 0.003921568627);
}

.bg-category-severe_weather {
  background-color: #f33101; //fallback color in case display-p3 is not supported
  background-color: color(display-p3 0.9529411765 0.1921568627 0.003921568627);
}

.bg-category-terror {
  background-color: #f35201; //fallback color in case display-p3 is not supported
  background-color: color(display-p3 0.9529411765 0.3215686275 0.003921568627);
}

.bg-category-health_warnings {
  background-color: #f37601; //fallback color in case display-p3 is not supported
  background-color: color(display-p3 0.9529411765 0.4549019608 0.003921568627);
}

.bg-category-infrastructure {
  background-color: #f39201; //fallback color in case display-p3 is not supported
  background-color: color(display-p3 0.9529411765 0.5725490196 0.003921568627);
}
