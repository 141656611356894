.selectable-icon-gallery {
  .label-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  input[type="radio"]:checked + .icon-wrapper-label {
    border: 1px solid #5145cd;

    svg path {
      @apply fill-current text-indigo-700;
    }
  }

  .icon-wrapper-label {
    @apply border bg-gray-50 border-gray-300;

    align-items: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 5px;

    &:hover {
      @apply bg-gray-100 border-gray-400;

      svg path {
        @apply fill-current text-gray-800;
      }
    }

    svg {
      height: 40px;
      width: 40px;
    }
  }
}
