// You'll need:
// 1 - An element with checkable-card class
// 2 - A check indicator(any asset) with checkable-card-indicador class

// Because this is radio button/checkbok targeted css component, we're relying on
// an hidden input being right before a label(containing all desired style)
input:checked + .checkable-card {
  @apply border-indigo-700;

  .checkable-card-indicador {
    @apply block;
  }
}

.checkable-card {
  @apply rounded cursor-pointer p-3 border border-gray-300 relative;

  .checkable-card-indicador {
    @apply top-2 right-2 hidden absolute;
  }
}
