.dot-indicator {
  @apply relative;

  &:before {
    @apply bg-red-600 absolute rounded-full h-2 w-2;
    content: '';
    top: 0;
    right: -.5rem;
  }
}
