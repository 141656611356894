.tippy-box[data-theme~='black'] {
  background-color: black;
  color: whitesmoke;
  border-radius: 0.75rem;
  border: 1px solid #000000;
  max-width: fit-content !important;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.tippy-box[data-theme~='black'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: black;
}

.tippy-box[data-theme~='black'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: black;
}

.tippy-box[data-theme~='black'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: black;
}

.tippy-box[data-theme~='black'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: black;
}

.tippy-box[data-theme~='white'] {
  background-color: white;
  color: black;
  border-radius: 0.75rem;
  border: 1px solid #e5e7eb;
  max-width: fit-content !important;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.tippy-box[data-theme~='white'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: white;
}

.tippy-box[data-theme~='white'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: white;
}

.tippy-box[data-theme~='white'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: white;
}

.tippy-box[data-theme~='white'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: white;
}
