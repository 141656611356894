/* TODO: With the Utility CSS style, we avoid creating specific classes. This should be directly in the markup -Mike */
.responsive-header {
  @apply bg-white shadow;

  &-nav {
    @apply flex items-center mx-auto px-3 h-12;
    @apply lg:h-16 lg:px-8;

    .caret-icon {
      @apply text-gray-300;
      min-width: 32px;
      min-height: 32px;
      width: 32px;
      height: 32px;
    }

    .title {
      @apply flex items-center font-bold leading-tight text-gray-700 text-xl;
      @apply lg:text-2xl;

      @media (max-width: 1024px) {
        span.no-left-menu {
          max-width: 55vw;
        }
      }
    }

    .link:hover {
      @apply text-indigo-600;

      .icon {
        @apply text-indigo-600;
      }
    }
  }
  .header-filter-container {
    @apply rounded-md px-3 pb-3 lg:px-0 lg:py-0 lg:ml-4;

    .header-filter {
      @apply flex justify-between p-1 bg-gray-100 rounded-md w-screen;
      @apply lg:w-auto lg:text-sm;
    }
  }
}

.standard-header {
  @apply mx-auto max-w-7xl py-4 px-4 flex justify-between flex-wrap;

  @media (min-width: 640px) {
    @apply px-6;
  }

  @media (min-width: 1024px) {
    @apply px-8;
  }

  .icon {
    @apply fill-current h-8 w-8 text-gray-700;

    &.secondary {
      @apply text-gray-300;
    }
  }

  .title {
    @apply text-xl font-bold leading-tight text-gray-700 flex items-center flex-wrap;

    .icon {
      @apply mr-2;
    }
  }

  .link:hover {
    @apply text-indigo-600;

    .icon {
      @apply text-indigo-600;
    }

    .title {
      @apply text-indigo-600;
    }
  }
}
