.tooltip {
  .tooltip-text {
    visibility: hidden;
    text-align: center;
    position: absolute;
    z-index: 100;
  }

  &:not(.not-hoverable):hover .tooltip-text {
    visibility: visible;
    bottom: 0;
    margin-bottom: 30px;
  }

  &:not(.not-hoverable):hover .bottom-text {
    bottom: -150%;
    margin-bottom: 0;
  }
}

.tooltip-container {
  position: relative;
  display: block;
}

.tippy-box[data-theme~='light'] .tippy-content {
  font-size: .75rem; // text-sm
}
