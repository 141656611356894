.menu-container {
  div:first-of-type.menu {
    @media (max-width: 1536px) {
      .menu-content {
        left: 0;
        transform: translateX(0);
      }
    }
  }
}

.menu {
  cursor: pointer;
  width: fit-content;

  &.full-width {
    width: 100%;

    .menu-content {
      max-width: 100%;
    }
  }

  .menu-content {
    display: none;
    position: absolute;
    max-width: max-content;

    // This is a fix for Safari browser having a delay while
    // rendering menu over a canvas. We're basically relying on hardware acceleration
    > div {
      -webkit-transform: translate3d(0, 0, 0);
    }

    &.header-element-1col {
      transform: translateX(-28%);

      .submenu-width {
        width: 18rem;
      }
    }

    &.header-element-2cols {
      transform: translateX(-35%);

      .submenu-width {
        width: 36rem;
      }
    }

    &.right-side {
      margin-left: -100px;
    }

    svg {
      display: inline;

      &:not([fill="none"]) {
        fill: currentColor;
      }
    }
  }


  &.active .menu-content {
    display: block;
  }

  &.hoverable:hover .menu-content {
    display: block;
  }
}

#floating-mobile-menu-btn {
  @apply block lg:hidden fixed p-2.5 rounded-md bg-indigo-700 text-indigo-300
  hover:text-white hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600
  focus:text-white z-10 shadow-lg;
}
