.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 32px;
  height: 32px;
  background: var(--thumb-color, rgb(80, 96, 238));
  border: 3px solid white;
  border-radius: 50%;
  cursor: pointer;
  transition: background .2s ease-in-out;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.range-slider::-moz-range-thumb {
  width: 32px;
  height: 32px;
  background: var(--thumb-color, rgb(80, 96, 238));
  border: 3px solid white;
  border-radius: 50%;
  cursor: pointer;
  transition: background .2s ease-in-out;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
