.team-climate {
  .score-5 {
    background-color: #0EBC14;
    border-color: #409643;
    color: #065209;
  }

  .score-4 {
    background-color: #3FC944;
    border-color: #28A83D;
    color: #065209;
  }

  .score-3 {
    background-color: #6FD773;
    border-color: #60C163;
    color: #065209;
  }

  .score-2 {
    background-color: #A1E5A4;
    border-color: #97CF99;
    color: #065209;
  }

  .score-1 {
    background-color: #D1F2D2;
    border-color: #CFDED1;
    color: #065209;
  }

  .score-0 {
    background-color: #FFFFFF;
    border-color: #E4E4E7;
    color: #B1B1B1;
  }

  .score-negative-1 {
    background-color: #FFD1D1;
    border-color: #E8B4B4;
    color: #540A0A;
  }

  .score-negative-2 {
    background-color: #FEA7A7;
    border-color: #ED7E7E;
    color: #540A0A;
  }

  .score-negative-3 {
    background-color: #FE7C7C;
    border-color: #FB5353;
    color: #540A0A;
  }

  .score-negative-4 {
    background-color: #FD4E4E;
    border-color: #D91A1A;
    color: #540A0A;
  }

  .score-negative-5 {
    background-color: #FC1F1F;
    border-color: #D11A1A;
    color: #540A0A;
  }

  .score-margin {
    margin-right: 2px;
  }

  .tooltip-border-color {
    border-color: #2564EB !important;
  }
}

.shadow-overflow-scroll {
  // Shadow effect 
  overflow-x: auto;
  background:
    // Shadow covers 
    linear-gradient(to right, #f9fafb 30%, rgba(255,255,255,0)),
    linear-gradient(to right, rgba(255,255,255,0), #f9fafb 70%) 0 100%,
    
    // Shadows
    linear-gradient(to right, rgba(0,0,0, 0.2), #f9fafb),
    linear-gradient(to left, rgba(0,0,0, 0.2), #f9fafb) 0 100%;
  
  background-repeat: no-repeat;
  background-color: #f9fafb;
  background-position: top left, top right, top left, top right;
  background-size: 40px 100%, 40px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;

  * {
    mix-blend-mode: multiply;
    // Fix for Safari to apply the multiply mix blend mode
    transform: translate3d(0,0,0);
  }
}

.shadow-overflow-scroll-bg-white {
  // Shadow effect 
  overflow-x: auto;
  background:
    // Shadow covers 
    linear-gradient(to right, white 30%, rgba(255,255,255,0)),
    linear-gradient(to right, rgba(255,255,255,0), white 70%) 0 100%,
    
    // Shadows
    linear-gradient(to right, rgba(0,0,0, 0.2), white),
    linear-gradient(to left, rgba(0,0,0, 0.2), white) 0 100%;
  
  background-repeat: no-repeat;
  background-color: white;
  background-position: top left, top right, top left, top right;
  background-size: 40px 100%, 40px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;

  * {
    mix-blend-mode: multiply;
    // Fix for Safari to apply the multiply mix blend mode
    transform: translate3d(0,0,0);
  }
}
