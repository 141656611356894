td[class*="indicator-"] {
  position: relative;
}

td[class*="indicator-"]::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 8px;
  z-index: 1; /* Ensure the indicator is on top of the cell */
}

td[class*="indicator-"] td {
  padding-left: 16px; /* Space for the indicator */
  position: relative;
  z-index: 2; /* Ensure the td elements are above the indicator */
}

.indicator-rose::before {
  background-color: rgb(225 29 72); /* rose-600 */
}

.indicator-orange::before {
  background-color: rgb(249 115 22); /* orange-500 */
}

.indicator-white::before {
  background-color: #ffffff; /* white */
}
