// Tailwind
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Base
@import 'base/_animations.scss';
@import 'base/_border.scss';
@import 'base/_cursor.scss';
@import 'base/_input.scss';
@import 'base/_spacing.scss';
@import 'base/_statuses.scss';
@import 'base/_utilities.scss';

// Components
@import 'components/_card.scss';
@import 'components/_checkable_card.scss';
@import 'components/_checkradio.scss';
@import 'components/_datepicker.scss';
@import 'components/_disruptions.scss';
@import 'components/_disruption_activities.scss';
@import 'components/_dropdowns.scss';
@import 'components/_header.scss';
@import 'components/_highchart.scss';
@import 'components/_icon.scss';
@import 'components/_image_gallery.scss';
@import 'components/_images.scss';
@import 'components/_indicators.scss';
@import 'components/_label.scss';
@import 'components/_menu.scss';
@import 'components/_navbar.scss';
@import 'components/_range_slider.scss';
@import 'components/_rich_text.scss';
@import 'components/_selectable_icon_gallery.scss';
@import 'components/_switch.scss';
@import 'components/_table.scss';
@import 'components/_tippy.scss';
@import 'components/_tooltip.scss';

// Pages
@import 'pages/_disruptions.scss';
@import 'pages/_work_climate.scss';

// Other
@import 'markdown.scss';


body {
  min-height: 100vh; // Fall back for unsupported browsers
  min-height: 100dvh;
}

// Weird things happen in Safari. For some reason in some pages, body is not taking all available height
// This explicity height definition was required to fix it
html {
  height: 100%;
}

#geofence_map {
  height: 32rem;
}
