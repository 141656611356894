.log-entry {
  @apply text-gray-700 text-xs ml-1 pl-6 mt-1 relative;

  &::before {
    @apply absolute bg-gray-300 rounded-full;
    content: '';
    width: 10px;
    height: 10px;
    top: 0;
    left: 0;
    transform: translate(-40%, 30%);
  }

  &::after {
    @apply absolute border-gray-300 border-l-2;
    content: "";
    width: 1px;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(-10%,-50%);
  }
}
