.is-18x18 {
  height: 18px;
  width:  18px;;

  svg {
    height: 18px;
    width:  18px;;
  }
}

.is-20x20 {
  height: 20px;
  width:  20px;;

  svg {
    height: 20px;
    width:  20px;;
  }
}


.fill-current {
  svg path {
    fill: currentColor;
  }
}
