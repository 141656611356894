// Disruptions mapbox tooltip
.mapboxgl-popup-content {
  border-radius: 10px !important;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1) !important;
  padding: 10px !important;
}

// Cross icon on tooltip
.cross-plus-icon {
  transition: transform 0.3s ease-in-out;

  &.active {
    transform: rotate(315deg);
  }
}

#side-panel-container {
  top: 5px;
  bottom: 5px;
  left: -100%;
  right: auto;
  transition: left 0.3s ease-in-out;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1) !important;

  &.active {
    left: 5px;
  }

  #close-side-panel {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  #side-panel-container {
    height: 60%;
    top: auto;
    bottom: -65%;
    left: 5px;
    right: 5px;
    transition: bottom 0.3s ease-in-out;

    &.active {
      bottom: 5px;
    }
  }
}
