.image-gallery {
  @apply flex flex-wrap;

  .image-container {
    @apply w-1/4;

    .image {
      @apply rounded w-48 h-24 align-middle border-none object-cover mt-2;;
    }

    &:not(:last-child) {
      @apply mr-2;
    }
  }

  &.has-primary-picture .image-container {
    &:first-child {
      @apply w-full  mr-0;
    }

    &:not(:first-child) {
      @apply mr-0 mt-2;
    }

    &:not(:last-child) {
      @apply mr-0;
    }

    &:not(:first-child):not(:last-child) {
      @apply pr-2;
    }

    &:not(:first-child) .image {
      @apply h-20;
    }

    .image {
      @apply w-full h-full mt-0;
    }
  }
}
