
.custom-radio {
  --check-left-distance: 0.5rem;
  --check-width: 1.125rem;

  input[type='radio'] {
    outline: 0;
    user-select: none;
    display: inline-block;
    position: absolute;
    opacity: 0;

    &~label {
      background-color: #fff;
      border: 1px solid #cacad5;
      border-radius: 5px;
      padding: 5px 5px 5px calc(var(--check-width) +  var(--check-left-distance) * 2  + 2px);
      width: 100%;
      height: 100%;
      position: relative;
      color: #000;
    }

    &~label:before {
      width: var(--check-width);
      height: var(--check-width);
      background-color: #f2f4f5;
      content: '';
      border-radius: 5px;
      position: absolute;
      left: .5rem;
      border: 1px solid #f2f4f5;
      top: 0.45rem;
    }

    &:checked~label {
      background-color: #1749ef;
      border-color: #1742df;
      color: #fff;

      &::after {
        --tick-width: 0.5rem;
        width: var(--tick-width);
        height: 0.9rem;
        top: 0.45rem;
        left: calc(var(--check-left-distance) + var(--check-width) / 2 - var(--tick-width) / 2 );
        content: '';
        position: absolute;
        box-sizing: border-box;
        transform: translateY(0rem) rotate(45deg);
        border-width: 0.2rem;
        border-style: solid;
        border-color: #2cba7a;
        border-top: 0;
        border-left: 0;
        display: inline-block;
      }

      &::before {
        background-color: #172d8c;
        border: 1px solid #1b2660;
      }
    }
  }
}
