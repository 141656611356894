.markdown {
  @apply text-gray-900 leading-normal;
}

.markdown > * + *, .markdown li > p + p {
  @apply mt-4;
}
.markdown p + p {
  @apply mt-4;
}

.markdown li + li {
  @apply mt-2;
}

.markdown ul ul {
  @apply my-2;
}
.markdown strong {
  @apply font-bold;
}

.markdown a, .prose a {
  @apply text-indigo-600 font-semibold;
}

.markdown strong a {
  @apply font-bold;
}
.markdown h2 {
  @apply leading-tight text-2xl font-bold text-black mb-2 mt-10;
}
.markdown h3 {
  @apply leading-tight text-xl font-bold text-black mt-8 -mb-2;
}
.markdown code {
  @apply font-mono text-sm inline bg-gray-100 px-1;
}
.markdown pre code {
  @apply block bg-black p-4 rounded;
}
.markdown blockquote {
  @apply border-l-4 border-gray-400 pl-4;
}
.markdown ul, .markdown ol {
  /*@apply pl-5;*/
  @apply pl-6 list-disc list-inside;
  @screen sm {
    @apply pl-14;
  }
}
.markdown abbr[title] {
  border: 0px;
  text-decoration: none;
  @apply border-b border-gray-300 border-solid pb-0;
}