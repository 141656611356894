// Hides the element it's attached to until Alpine.js is fully loaded on the page
[x-cloak] {
  display: none !important;
}

// Text orientation classes
.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.safari-scroll-fix {
  -webkit-overflow-scrolling: touch;
}

.scroll-gradient {
  background: 
    linear-gradient(#ffffff 33%, rgba(255,255,255, 0)),
    linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(102,102,102, 0.5), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 50% 100%, rgba(102,102,102, 0.5), rgba(0,0,0,0)) 0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 48px, 100% 48px, 100% 16px, 100% 16px;
}
