.card {
  @apply bg-white shadow rounded-lg;

  .card-body {
    @apply flex justify-items-start p-4;
  }

  .card-icon {
    @apply mr-2 rounded-full p-2 block;
    flex-shrink: 0;
  }

  .card-title {
    @apply text-lg leading-7 font-medium;
  }

  .card-description {
    @apply text-sm;
  }

  .card-footer {
    @apply grid grid-flow-col;
  }

  .card-action {
    @apply col-span-1 flex justify-center border-t p-2 font-medium text-gray-700;

    &:not(:first-child) {
      @apply border-l;
    }
  }
}

.horizontal-card {
  .card-left {
    &.cool-gray {
      background-color: #f9fafb; // cool-gray-50

      svg {
        fill: #9ca3af; // cool-gray-400

        g {
          path {
            fill: #9ca3af;
          }

          g {
            stroke: #9ca3af;
          }
        }
      }
    }

    &.rose {
      background-color: #fda4af; // rose-300

      svg {
        fill: #e11d48; // rose-600

        g {
          path {
            fill: #e11d48;
          }

          g {
            stroke: #e11d48;
          }
        }
      }
    }

    &.green {
      background-color: #bbf7d0; // green-200

      svg {
        fill: #16a34a; // green-600

        g {
          path {
            fill: #16a34a;
          }

          g {
            stroke: #16a34a;
          }
        }
      }
    }

    &.light-blue {
      background-color: #bae6fd; // light-blue-200

      svg {
        fill: #0086cd; // light-blue-500
      }
    }
  }
}
