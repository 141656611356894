.label-with-pill {
  --big-pill-size: 0.7rem;
  --small-pill-size: calc(var(--big-pill-size)/2);
  padding-left: calc(var(--big-pill-size) * 2 );
  @apply relative;

  &:before, &:after {
    @apply rounded-full absolute;
    content: '';
  }

  &:before {
    @apply bg-green-700;
    height: var(--big-pill-size);
    left: calc(var(--big-pill-size)/2);
    top: calc(50% - var(--big-pill-size)/2);
    width: var(--big-pill-size);
  }

  &.active-label:after {
    --pills-size-diff: calc(var(--big-pill-size) - var(--small-pill-size));
    height: var(--small-pill-size);
    left: calc(var(--big-pill-size)/2 + var(--pills-size-diff)/2);
    top: calc(50% - var(--small-pill-size)/2);
    width: var(--small-pill-size);
  }

  &.pill-light.active-label:after {
    @apply bg-cyan-50;
  }

  &.pill-normal.active-label:after {
    @apply bg-cyan-300;
  }
}
