@keyframes move-left-right {
  0% { transform: translateX(-10px); }
  100% { transform: translateX(10px); }
}

.animate-left-to-right {
  animation: move-left-right 0.5s linear infinite alternate;
}

@keyframes blink {
  0% {opacity: 1.0;}
  50% {opacity: 0.0;}
  100% {opacity: 1.0;}
}

.blinking-cursor {
  animation-name: blink;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
}
