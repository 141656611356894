.switch {
  @apply bg-gray-200 relative inline-flex shrink-0 h-6 w-11
         border-2 border-transparent rounded-full cursor-pointer
         transition-colors ease-in-out;

  &:focus {
    @apply outline-none;
  }

  .switch-container {
    @apply translate-x-0 pointer-events-none relative inline-block
           h-5 w-5 rounded-full bg-white shadow transform transition
           ease-in-out duration-200;

    .icon {
      @apply absolute inset-0 h-full w-full flex items-center justify-center transition-opacity;
    }

    .icon-disabled {
      @apply opacity-100 ease-in;
    }

    .icon-enabled {
      @apply opacity-0 ease-out;
    }
  }

  &.enabled {
    @apply bg-indigo-600;

    .switch-container {
      @apply translate-x-5;
    }

    .icon-disabled {
      @apply opacity-0 ease-out;
    }

    .icon-enabled {
      @apply opacity-100 ease-in;
    }
  }
}

