.qs-controls {
  background-color: #4c51bf;
  color: #fff;
}

.qs-arrow.qs-right:after {
  border-left-color: #fff;
}

.qs-arrow.qs-left:after {
  border-right-color: #fff;
}

.qs-datepicker-container {
  border: 0px;
  box-shadow: 0 1.25em 2.25em -0.9375em rgba(0,0,0,.3);
}

.qs-square {
  height: 1.8625em;
  color: #2D3748 !important;

  &:hover {
    background: #FBD38D !important;
  }
}

.qs-day {
  font-size: 12px;
  color: #A0AEC0 !important;
}

.qs-month-year:hover {
  border-bottom: 1px solid #fff;
}
