// This is a fix for Safari not supporting line-clamp on rich text content.
.rich-text-line-clamp {
  & > * {
    display: inline;

    ::after {
      content: " \A\A";
      white-space: pre;
    }
  }
}
